import { CircularProgress, MenuItem, Select, TextField } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Get } from "../service/getway";

import { Sidebar } from "./Charts/sidebar";
import { EventTable } from "./eskom";

export default function Dashboard() {
  const [zoneFilter, setZoneFilter] = useState("");
  const [events, setEvents] = useState([]);
  const [currentTime] = useState(moment());
  const [searchText, setSearchText] = useState("");
  
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  
  const [siteDetails, setSiteDetails] = useState([])

  const token = useSelector((state) => state.auth.token);

  const filteredData = data.filter((entry) => {
    if (zoneFilter !== "" && entry.Zone !== zoneFilter) {
      return false;
    }
    if (
      searchText !== "" &&
      !entry.SiteName.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return false;
    }
    return true;
  });
  
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await Get("/site", token);
        setData(result.data.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    const fetchEventData = async () => {
      setLoading(true);

      Get("/eskom", token)
        .then((response) => {
        
          Get("/site", token)
          .then(async(sites_response) => {
            const sites = Object.entries(sites_response.data);
            let siteArray =  sites[1][1]
            const { data } = response.data;
            for (let j = siteArray.length -1; j >= 0 ; j--) {
              for (let i =0; i < data.length; i++) {
                if(data[i].ESPID ===  siteArray[j].ESPID){
                  data[i].zone = siteArray[j].Zone
                }
              }
            }
          
          const filtedObject  = data.map(items => {
             if (moment(items.start) <= moment().startOf("hour").add(2, "hour") && moment(items.end) >= moment.now()){
                return items;
             }
            })
          const sortedObject = filtedObject.sort(((a, b) => a.start - b.start))
          setEvents(sortedObject.filter(item => item));
          setLoading(false);
        })
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    };

    fetchData();
    fetchEventData();
  }, []);

  return (
    <div>
      <h2 className="main-header" style={{ color: "black" }}>
        Dashboard
      </h2>
      <hr />
     
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {" "}
          <Sidebar data={filteredData} loadshedingdata={events}  />
          <hr />
          <br />
          

          <h2>Loadshedding</h2>
          <EventTable events={events} currentTime={currentTime} />

        </>
      )}
      <br />
    </div>
  );
}

const Row = styled.div`
  display: flex;
  padding: 1rem 0;
`;

const S = styled.div`
  padding: 0.6rem;
`;
