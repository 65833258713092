import React, { useEffect, useState } from "react";
import { Get } from "../service/getway";
import SlaTable from "./Table/slaTable";
import { useSelector } from "react-redux";

export default function Sla() {
  const [slas, setSlas] = useState([]);
  const [loading, setLoading] = useState(true);
  const {token}=  useSelector((state) => state.auth)


  useEffect(() => {
    const fetchSlas = async () => {
      try {
        // Start loading
        setLoading(true);
        const response = await Get("https://atlas-ov.sys.echosp.link/api/sla", token);
        // Assuming the response data is an array of SLAs
        const slasData = response;
        console.log("SLAs:", slasData);
        // Set the fetched SLAs to state
        setSlas(slasData.data);
        // Stop loading
        setLoading(false);
      } catch (error) {
        console.error("Error fetching SLAs:", error);
        // Handle error
        // Stop loading
        setLoading(false);
      }
    };

    fetchSlas();
  }, []);

  return (
    <div>
      <h2 className="main-header" style={{ color: "black" }}>
        SLA
      </h2>

      <SlaTable data={slas} loading={loading} />
    </div>
  );
}
