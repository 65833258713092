import * as React from "react";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Container from "@mui/material/Container";
import QRCode from "react-qr-code";
import { Card, CardContent, Typography } from "@mui/material";
import { Get } from "../service/getway";
import { useSelector } from "react-redux";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://port443.co.za/">
        Port443
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function LinkMAF() {
  const [response, setResponse] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const { enqueueSnackbar } = useSnackbar();

  const temp_id = useSelector((state) => state.auth.tempId);

  React.useEffect(() => {
    Get(`/link-code/${temp_id}`)
      .then((response) => {
        setResponse(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        enqueueSnackbar("Failed to get response", { variant: "error" });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h2 className="main-header" style={{ color: "black" }}>
          Atlas One View
        </h2>
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography
          component="h1"
          variant="h5"
          sx={{ color: "secondary.main" }}
        >
          Setup MFA
        </Typography>
        {loading ? (
          <CircularProgress />
        ) : response && response.message === "success" ? (
          <Card>
            <CardContent>
              <Typography variant="h5" component="h2">
                Scan the code using an app
              </Typography>
              <QRCode value={response.uri} />
            </CardContent>
          </Card>
        ) : (
          <p>No response available</p>
        )}
        <Box>
          <Link href="/mfa" variant="body2">
            verify mfa
          </Link>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
