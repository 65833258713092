import React from "react";
import { Chart } from "react-google-charts";

export const data = [
  ["Availabity", "Hours per Day"],
  ["Up", 90],
  ["Down", 0],
  ["Up-Down", 8],
 
];

export const options = {
  title: "Availablity",
  is3D: true,
  colors: ['#0f9d58', '#db4437', '#f4b400'],
};

export function Pie() {
  return (
    <Chart
      chartType="PieChart"
      data={data}
      options={options}
      width={"100%"}
      height={"200px"}
    />
  );
}
