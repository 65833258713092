import React from "react";
import styled from "styled-components";
import { Chart } from "react-google-charts";
import { Get } from "../service/getway";
import TimeSeriesChart from "./TimeSeriesChart";
import { useSelector } from "react-redux";

const DashboardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const PanelContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 24px;
  max-width: 1200px;
`;

const Panel = styled.div`
  background-color: #f0f0f0;
  padding: 16px;
  border-radius: 8px;
  color: #333;
`;

const PanelTitle = styled.h2`
  color: #333;
  margin-bottom: 16px;
`;

const PanelContent = styled.p`
  color: #555;
`;

const ChartContainer = styled.div`
  margin-top: 16px;
`;

const ZoneDashboard = () => {
  const wanStatusData = [
    ["Date", "WAN Status"],
    ["01/04", 1],
    ["01/05", 0],
    ["01/06", 1],
    ["01/07", 1],
    ["01/08", 0],
    ["01/09", 1],
    ["01/10", 1],
  ];

  const packetLossData = [
    ["Date", "Packet Loss (%)"],
    ["01/04", 1.2],
    ["01/05", 0.5],
    ["01/06", 0.8],
    ["01/07", 0.7],
    ["01/08", 1.3],
    ["01/09", 1.0],
    ["01/10", 0.6],
  ];

  const jitterData = [
    ["Date", "Jitter (ms)"],
    ["01/04", 10],
    ["01/05", 7],
    ["01/06", 12],
    ["01/07", 8],
    ["01/08", 15],
    ["01/09", 9],
    ["01/10", 6],
  ];

  const getWanStatus = () => {
    const site_data  = localStorage.getItem('dash_data');
    // Replace with actual API call to fetch WAN status
    const sitestatuData_url ='https://atlas-ov.sys.echosp.link/api/status';
    Get(sitestatuData_url, token)
      .then((status_response) => {

        
    })
    return "Up";
  };

  const getPacketLoss = () => {
    // Replace with actual API call to fetch packet loss percentage
    return "1.2%";
  };

  const getJitter = () => {
    // Replace with actual API call to fetch jitter in milliseconds
    return "10 ms";
  };

  const [siteData, setSiteData] = React.useState(null);

  const sitename = window.location.pathname.split("/").pop();
  const token = useSelector((state) => state.auth.token);


  React.useEffect(() => {
  
  
    Get(`site/${sitename}`, token)
      .then((response) => setSiteData(response.data))
      .catch((error) => console.log(error));
  }, []);

  return (
    <>
      <h2 className="main-header" style={{ color: "black" }}>
        Zone Detail - {sitename}
      </h2>
      <DashboardContainer>
        <PanelContainer>
          <Panel>
            <PanelTitle>WAN1 Status</PanelTitle>
            <PanelContent>{getWanStatus()}</PanelContent>
          </Panel>
          <Panel>
            <PanelTitle>Packet Loss</PanelTitle>
            <PanelContent>{getPacketLoss()}</PanelContent>
          </Panel>
          <Panel>
            <PanelTitle>Jitter</PanelTitle>
            <PanelContent>{getJitter()}</PanelContent>
          </Panel>
          <Panel>
            <PanelTitle>WAN2 Status</PanelTitle>
            <PanelContent>{getWanStatus()}</PanelContent>
          </Panel>
          <Panel>
            <PanelTitle>Packet Loss</PanelTitle>
            <PanelContent>{getPacketLoss()}</PanelContent>
          </Panel>
          <Panel>
            <PanelTitle>Jitter</PanelTitle>
            <PanelContent>25 ms</PanelContent>
          </Panel>
          <Panel>
            <PanelTitle>WAN Status Chart</PanelTitle>
            <ChartContainer>
              <Chart
                width={"100%"}
                height={"200px"}
                chartType="LineChart"
                data={wanStatusData}
                options={{
                  title: "WAN Status",
                  hAxis: {
                    title: "Date",
                  },
                  vAxis: {
                    title: "WAN Status",
                  },
                  legend: "none",
                  colors: ["#007bff"],
                }}
              />
            </ChartContainer>
          </Panel>
          <Panel>
            <PanelTitle>Packet Loss Chart</PanelTitle>
            <ChartContainer>
              <Chart
                width={"100%"}
                height={"200px"}
                chartType="LineChart"
                data={packetLossData}
                options={{
                  title: "Packet Loss (%)",
                  hAxis: {
                    title: "Date",
                  },
                  vAxis: {
                    title: "Packet Loss (%)",
                  },
                  legend: "none",
                  colors: ["#dc3545"],
                }}
              />
            </ChartContainer>
          </Panel>
          <Panel>
            <PanelTitle>Jitter Chart</PanelTitle>
            <ChartContainer>
              <Chart
                width={"100%"}
                height={"200px"}
                chartType="LineChart"
                data={jitterData}
                options={{
                  title: "Jitter (ms)",
                  hAxis: {
                    title: "Date",
                  },
                  vAxis: {
                    title: "Jitter (ms)",
                  },
                  legend: "none",
                  colors: ["#28a745"],
                }}
              />
            </ChartContainer>
          </Panel>
          <TimeSeriesChart />
        </PanelContainer>
      </DashboardContainer>
    </>
  );
};

export default ZoneDashboard;
