import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { DatePicker } from "@mui/x-date-pickers";
import { Divider, FormControl } from "@mui/material";
import styled from "styled-components";

const TicketTable = (data, loading) => {
  // console.log(data, "Incoming data");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [fromDate, setFromDate] = React.useState();
  const [toDate, setToDate] = React.useState();

  const handleFromDateChange = (date) => {
    setFromDate(date.$d.toLocaleDateString());
  };

  const handleToDateChange = (date) => {
    setToDate(date.$d.toLocaleDateString());
  };

  const apiData = data.data;

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <FormControl>
{/*#        <Row>
#          <DatePicker
#            label="Start Date"
#            defaultValue={fromDate}
#            required
#            onChange={(newValue) => handleFromDateChange(newValue)}
#          />
#          <Divider orientation="vertical" />
#          <DatePicker
#            label="End Date"
#            required
#            value={toDate}
#            onChange={(newValue) => handleToDateChange(newValue)}
#          />
#        </Row> */}
      </FormControl>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Ticket Number</TableCell>
              <TableCell align="left">Assigned To</TableCell>
              <TableCell align="left">Logged By</TableCell>
              <TableCell align="left">Status</TableCell>
              <TableCell align="left">Logged Priority Id </TableCell>
              <TableCell align="left">Last Status Change Date</TableCell>
              <TableCell align="left">Logged Date</TableCell>
              <TableCell align="left">logged Description</TableCell>
            </TableRow>
          </TableHead>
          {apiData.length > 0 ? (
            <TableBody>
              {apiData.map((row) => (
                <TableRow
                  key={row.ticketNumber}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="left">{row.ticketNumber}</TableCell>
                  <TableCell align="left">{row.AssignedTo}</TableCell>
                  <TableCell align="left">
                    {row.firstName + " " + row.lastName}
                  </TableCell>
                  <TableCell align="left">{row.Status}</TableCell>
                  <TableCell align="left">{row.loggedPriorityId}</TableCell>
                  <TableCell align="left">{row.lastStatusChangeDate}</TableCell>
                  <TableCell align="left">{row.loggedDate}</TableCell>
                  <TableCell align="left">{row.loggedDescription}</TableCell>
                  {/* <TableCell align="left">
                                <Button variant="outlined" size="medium" endIcon={<SendIcon />}
                                  onClick={() => {
                                    // handleSiteUpdate()
                                  }}
                                >
                                  Update Site
                                </Button>
                            </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          ) : loading ? (
            <div s>
              <Box
                sx={{
                  display: "flex",
                  padding: "1rem",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <CircularProgress />
              </Box>
            </div>
          ) : (
            "No data"
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[50, 100, 200]}
        component="div"
        count={apiData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
export default TicketTable;

const Row = styled.div`
  display: flex;
  padding: 0.5rem;
`;
