import React, { useEffect, useState } from "react";
import { Get } from "../service/getway";
import InvoiceTable from "./Table/invoiceTable";
import { useSelector } from "react-redux";

export default function Invoice() {
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const {token}=  useSelector((state) => state.auth)


  useEffect(() => {
    const fetchInvoices = async () => {
     
      try {
        // Start loading
        setLoading(true);
        const response = await Get("https://atlas-ov.sys.echosp.link/api/invoice", token);
        // Assuming the response data is an array of invoices
        console.log("Resposne::",response.data)
        const invoicesData = response;
        // Set the fetched invoices to state
        setInvoices(invoicesData.data);
        // Stop loading
        setLoading(false);
      } catch (error) {
        console.error("Error fetching invoices:", error);
        // Handle error
        // Stop loading
        setLoading(false);
      }
    };

    fetchInvoices();
  }, []);

  return (
    <div>
      <h2 className="main-header" style={{ color: "black" }}>
        Invoices
      </h2>
      <InvoiceTable data={invoices} loading={loading} />
    </div>
  );
}
