import React, { useState, useEffect } from "react";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
// import { Get } from "../service/getway";
// import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper
} from "@mui/material";

export const ZoneDown = ({ loading }) => {
    const location = useLocation();
    const receivedData = location.state.data; // Access the sent data
    
    return (
        <Paper> 
        <h4 style={{ fontSize: "1.4rem" }}>Down Sites </h4>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Site Name</TableCell>
              <TableCell>Underlay 1</TableCell>
              <TableCell>Underlay 2</TableCell>
              <TableCell>Is Loadshedding</TableCell>
              <TableCell>Zone</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <CircularProgress />
            ) : (
                receivedData.map((entry) => (
                
                <TableRow
                  key={entry.ID} 
                >
                <TableCell>{entry.site_value.devname}</TableCell>
                  <TableCell>{entry.site_value.underlay1}</TableCell>
        
                  <TableCell>
                    {entry.site_value.underlay2}
                  </TableCell>
                  <TableCell>
                    {entry.site_value.sheding_time}
                  </TableCell>
                  <TableCell>
                    {" "}
                    {entry.site_value.zone}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
</Paper>
    );
};
export default ZoneDown;
