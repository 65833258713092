import React, { useState, useEffect } from "react";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
// import { Get } from "../service/getway";
// import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper
} from "@mui/material";
import { Tab } from "semantic-ui-react";

export const WarningSite = ({ loading }) => {

    // const upinterface = (activeInterface) => {
      
    //   if (activeInterface === "ADVPN-SPOKE1"){
    //     return "ADVPN-SPOKE1"
    //   }else if(activeInterface === "ADVPN-SPOKE2"){
    //     return "ADVPN-SPOKE2"
    //   }
      
    // };
    
    const eventRowStyle = (activeInterface) => {
      // const isCurrentTimeInRange = moment().isBetween(start, end);
      
      let backgroundColor = "";
    
      if (activeInterface === "ADVPN-SPOKE1") {
        backgroundColor = "#33cc33";
      }
      // else if (activeInterface === "ADVPN-SPOKE2"){
      //   backgroundColor = "#ffffff";
      // }
    
      return {
        backgroundColor,
        color: "000000",
        // fontWeight: "bold",
      };
    };
    
    const eventRowStyle2 = (activeInterface) => {
      // const isCurrentTimeInRange = moment().isBetween(start, end);
      
      let backgroundColor = "";
    
      if (activeInterface === "ADVPN-SPOKE2") {
        backgroundColor = "#33cc33";
      }
      // if (activeInterface === "ADVPN-SPOKE2"){
      //   backgroundColor = "#33cc33";
      // }
    
      return {
        backgroundColor,
        color: "000000",
        // fontWeight: "bold",
      };
    };
  
    const location = useLocation();
    const receivedData = location.state.data; // Access the sent data
    console.log("receivedData",receivedData)
    return (
        
        <Paper> 
        <h4 style={{ fontSize: "1.4rem" }}>Up Down Sites </h4>
        <Table>
          <TableHead>
            <TableRow>
        
              <TableCell>Site Name</TableCell>
              <TableCell>Underlay 1</TableCell>
              <TableCell>Underlay 2</TableCell>
              <TableCell>Up Interface</TableCell>
              <TableCell>Is Loadshedding</TableCell>
	      <TableCell>Zone</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <CircularProgress />
            ) : (
                receivedData.map((entry) => (
                
                <TableRow
                //   key={entry.ID} 
                >
                <TableCell>{entry.site_value.devname}</TableCell>
                  <TableCell >
                    {entry.site_value.underlay1}
                  </TableCell>
        
                  <TableCell >
                    {entry.site_value.underlay2}
                  </TableCell>
                  <TableCell>
                    {entry.site_value.interface}
                  </TableCell>
		  <TableCell>
                    {entry.site_value.sheding_time}
                  </TableCell>

                  <TableCell>
                    {" "}
                    {entry.site_value.zone}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
</Paper>
    );
};


export default WarningSite;
