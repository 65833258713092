import React, { useState } from "react";
import { Chart } from "react-google-charts";
import { CircularProgress } from "@mui/material";
import { Get } from "../service/getway";
import { useSelector } from "react-redux";

const TimeSeriesChart = () => {
  console.log("calling ...");
  const [APIData, setAPIData] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state.auth.token);


  function formatData(data) {
    console.log(data, "killing");
    const rows = data.data.map((value, index) => [
      new Date(data.starttime * 1000 + index * data.step * 1000),
      value[0],
    ]);

    setAPIData([[{ type: "date", label: "Time" }, "Availability"], ...rows]);
  }

  React.useEffect(() => {
    setLoading(true);
    Get("/graph", token)
      .then((response) => {
        const array = response.data.message.data;

        formatData(array);
        setLoading(false);

        console.log(APIData, "response");
      })

      .catch((error) => {
        console.log(error);
        setLoading(false);
        console.log("http", "hello");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = {
    chart: {
      title: "Availability Over Time",
      hAxis: {
        title: "Time",
      },
      vAxis: {
        title: "Availability",
      },
    },
    width: 700,
    height: 400,
    series: {
      // Gives each series an axis name that matches the Y-axis below.
      0: { axis: "Availability" },
    },
    axes: {
      // Adds labels to each axis; they don't have to match the axis names.
      y: {
        Temps: { label: "Temps (Celsius)" },
        Daylight: { label: "Daylight" },
      },
    },
  };

  // Format the data for use with Google Charts

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <Chart
          chartType="Line"
          width="100%"
          height="400px"
          data={APIData}
          options={options}
        />
      )}
    </>
  );
};

export default TimeSeriesChart;
