import { Alert, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Get } from "../service/getway.js";
import TicketTable from "./Table/TicketTable.jsx";

export default function Support() {
  const {token}=  useSelector((state) => state.auth)
  const [APIData, setAPIData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = React.useState(null);
  useEffect(() => {
    setLoading(true);
    const local_api = `/tickets`;
    Get(local_api, token)
      .then((response) => {
        const res_data = response.data.data;

        setAPIData(res_data);
        setLoading(false);
      })
      .catch((err) => {
        setError("Invalid username or password.");
      });
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setError(null);
  };

  return (
    <div>
      <h2 className="main-header" style={{ color: "black" }}>
        Support
      </h2>

      <TicketTable data={APIData} loading={loading} />

      <Snackbar
        open={error !== null}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {error ?? "Something went wrong, please try again."}
        </Alert>
      </Snackbar>
    </div>
  );
}
