import axios from "axios";

const Axios = () => {
  const apiHost =
    "https://mwvk03ot91.execute-api.af-south-1.amazonaws.com/Stage";
    //  "http://127.0.0.1:3002";
  /**
   * Base url for API calls
   */
  const axiosInstance = axios.create({
    baseURL: apiHost,
    validateStatus: (status) => {
      let correct = false;

      if (status >= 200 && status < 300) {
        correct = true;
      } else if (status >= 400 && status < 500) {
        correct = true;
      }

      return correct;
    },
    timeout: 180000,
  });

  return axiosInstance;
};

export const axiosObject = axios;

export default Axios;
