/* eslint-disable @typescript-eslint/no-explicit-any */
// import { AxiosResponse } from "axios";
import Axios from "./config.js";

// import Axios from "./config";

const Ajax = (userToken) => {
  const instance = Axios();

  if (userToken) {
    const accessToken = userToken;

    instance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  }

  return instance;
};

/**
 * Monitor response status
 */
const useMonitorFn = (response, monitor) => {
  const { status } = response;
  // const { logout } = useStoreActions((actions) => actions.auth);
  // console.log('MonitorLJHSAJDK', monitor)

  if (typeof monitor === "boolean" && monitor && status) {
    switch (status) {
      case 401:
        localStorage.clear();
        // Then redirect user to login page
        window.location.href = `/login`;
        break;
      default:
        break;
    }
  }
};

export const Get = async (
  url,
  token,
  data,
  monitor = false,
  timeout = null
) => {
  const inputData = data || {};
  // // console.log('tokenaslkdjsaldj', token)
  // parseJwt(token)
  const response = await Ajax(token).get(url, {
    params: inputData,
    timeout,
  });
  useMonitorFn(response, true);
  return response;
};

export const Post = async (
  url,
  token,
  data,
  monitor = false,
  timeout = null
) => {
  // // console.log('tokenaslkdjsaldj', token)
  // parseJwt(token)
  const response = await Ajax(token).post(url, data, {
    timeout,
  });

  useMonitorFn(response, true);

  return response;
};

export const Update = async (
  url,
  token,
  data,
  monitor = false,
  timeout = null
) => {
  // console.log('tokenaslkdjsaldj', token)
  // parseJwt(token)
  const response = await Ajax(token).put(url, data, {
    timeout,
  });

  useMonitorFn(response, monitor);

  return response;
};

export const Delete = async (
  url,
  token,
  data,
  monitor = false,
  timeout = null
) => {
  // console.log('tokenaslkdjsaldj', token)
  // parseJwt(token)
  const inputData = data || {};

  const response = await Ajax(token).delete(url, {
    data: inputData,
    timeout,
  });

  useMonitorFn(response, monitor);

  return response;
};


const parseJwt = (token) => {        
  const decode = JSON.parse(atob(token.split('.')[1]));
  console.log(decode);
  if (decode.exp * 1000 < new Date().getTime()) {
      // logoutAction();
      // history.push('/down', event.data);
      console.log('Time Expired');
      window.location.href = `/login`;

  }
};