import React from "react";
import { Route, Redirect } from "react-router-dom";
import ButtonAppBar from "../components/menubar";
import styled from "styled-components";
import { useSelector } from "react-redux";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          <>
            <ButtonAppBar />
            <Container>
              <Component {...props} />
            </Container>
          </>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

const Container = styled.div`
  padding: 3rem;
  margin: 2rem;
`;

export default PrivateRoute;
