import React, { useState } from "react";
import { MenuItem, Select, TextField } from "@mui/material";
import styled from "styled-components";
import { useSelector } from "react-redux";

import { Get } from "../service/getway";
import MyTable from "./Table/U";

export default function Read() {
  const [loading, setLoading] = useState(false);
  const [zoneFilter, setZoneFilter] = useState("");
  const [searchText, setSearchText] = useState("");

  const [data, setData] = useState([]);
  const handleZoneChange = (event) => {
    setZoneFilter(event.target.value);
  };

  const handleSearchTextChange = (event) => {
    setSearchText(event.target.value);
  };

  const token = useSelector((state) => state.auth.token);

  const filteredData = data.filter((entry) => {
    if (zoneFilter !== "" && entry.Zone !== zoneFilter) {
      return false;
    }
    if (
      searchText !== "" &&
      !entry.SiteName.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return false;
    }
    return true;
  });

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await Get("/site", token);
        setData(result.data.data);

        // dispatch(setFilteredData(filteredData));
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <Row>
        <TextField
          label="Search"
          value={searchText}
          onChange={handleSearchTextChange}
        />
        <S />
        <Select
          sx={{
            width: "10rem",
          }}
          margin={10}
          value={zoneFilter}
          onChange={handleZoneChange}
        >
          <MenuItem value="">All</MenuItem>
          <MenuItem value="Zone 1">Zone 1</MenuItem>
          <MenuItem value="Zone 2">Zone 2</MenuItem>
          <MenuItem value="Zone 3">Zone 3</MenuItem>
        </Select>
      </Row>
      <h4 style={{ color: "#cccc", fontSize: "1.4rem" }}>Zone List</h4>
      <br />
      <MyTable loading={loading} data={filteredData} />
    </div>
  );
}

const Row = styled.div`
  display: flex;
  padding: 1rem 0;
`;

const S = styled.div`
  padding: 0.6rem;
`;
