import React from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  FormControl,
  CircularProgress,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const MyTable = ({ data, loading }) => {

  const token = useSelector((state) => state.auth.token);

  return (
    <Paper>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "16px",
        }}
      >
        <FormControl></FormControl>
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Site Name</TableCell>
            <TableCell>Product</TableCell>
            <TableCell>Contract ID</TableCell>
            <TableCell>Customer Name</TableCell>
            <TableCell>Zone</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <CircularProgress />
          ) : (
            data.map((entry) => (
              <TableRow
                key={entry.SiteName}
                component={Link}
                to={`/sites/${entry.SiteName}`}
              >
                <TableCell>{entry.SiteName}</TableCell>
                <TableCell>{entry.Product}</TableCell>
                <TableCell>{entry.ContractID}</TableCell>
                <TableCell>{entry.CustomerName}</TableCell>
                <TableCell>{entry.Zone}</TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default MyTable;
