import { CircularProgress } from "@mui/material";
import React from "react";
import { Chart } from "react-google-charts";
import { Get } from "../../service/getway";
import moment from "moment";
// import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';


export const options = {
  chart: {
    title: "Number of sites per Zone",
    subtitle: "",
    colors: ["#33FF80", "#FF9033", "#FF3333"],
  },
  hAxis: {
    title: "Total number of sites",
    minValue: 0,
    titleTextStyle: {
      color: "#000",
      fontName: "sans-serif",
      fontSize: 50,
      bold: true,
      italic: false
  }
  },
  vAxis: {
    title: "Zone",
    titleTextStyle: {
      color: "#000",
      fontName: "sans-serif",
      fontSize: 50,
      bold: true,
      italic: false
  }
  },
  bars: "horizontal",
  axes: {
    y: {
      0: { side: "right" },
    },
  },
  dataLabels: {
    enabled: true,
    color: "#000",
    fontSize: 50,
    bold: true,
    format: "#",
  },
  
};

const handleSelect = (chartWrapper) => {
  // console.log(chartWrapper, "sdfghjklkjhgfdsdf");
};




export function Sidebar({loadshedingdata}) {

  const [sites, setsites] = React.useState([]);
  const [siteDetails, setSiteDetails] = React.useState([])
  const [selectedBar, setSelectedBar] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const {token}=  useSelector((state) => state.auth)
  const history = useHistory();

  React.useEffect(() => {
     fetchEventData();
     // Set interval to fetch data every 10 minutes (600,000 milliseconds)
    const intervalId = setInterval(fetchEventData, 600000);
    // Clean up the interval when the component is unmounted
    return () => clearInterval(intervalId);

  }, []);

  const fetchEventData = async () => {
    setLoading(true);
    const site_obeject = [];
    let newlist = [];
    Get("/site", token)
      .then(async(response) => {
        const { data } = response.data;

        const sitestatuData_url ='https://atlas-ov.sys.echosp.link/api/status';
        await Get(sitestatuData_url, token)
          .then((status_response) => {
            let statusCount = {};
            Object.values(status_response.data).forEach((item) => {
              const { devid, status } = item;
              if (status === 'up') {
                if (statusCount[devid]) {
                  statusCount[devid]++;
                } else {
                  statusCount[devid] = 1;
                }
              }
            });
            const uniqueObjects = Object.entries(statusCount).map(([devid, count]) => ({
              devid,
              count,
            }));
            
            const uniqueData = Object.values(status_response.data).reduce((acc, curr) => {
              const devid = curr.devid;
              uniqueObjects.map((obj, index) =>{
                if (curr.devid === obj.devid){
                    if(!acc[curr.devid]){
                      if(obj.count >= 2) {
                        curr.count = obj.count;
                        curr.status = 'up';
                        acc[devid] = curr
                      }
                      if(obj.count === 1) {
                        curr.count = obj.count;
                        curr.status = 'updown';
                        acc[devid] = curr
                      }
                    }
                }
              })
              return acc;
              
            }, {});

            const list_statuses = Object.entries(uniqueData);
            list_statuses.map((site_status_data) => {

              if(data.length > 0) {
                data.map((site) => {
                  if(site.SerialNo == site_status_data[1].devid){
                    site_obeject.push ({
                      devid: site_status_data[1].devid,
                      devname: site_status_data[1].devname,
                      site_status: site_status_data[1].status,
                      date: site_status_data[1]._col0,
                      interface: site_status_data[1].interface,
                      count: site_status_data[1].count,
                      zone: site.Zone,
                      siteName: site.SiteName,
                      underlay1: site.Underlay1,
                      underlay2: site.Underlay2,
                    })
                  }
                })
              }
            })
          
            newlist = [...site_obeject]
            data.forEach(item => {    
            
              if(item.SerialNo !== "FGT40FTK2209A1L9") { 
                if(!site_obeject.includes(item.SerialNo)) {  
                  newlist.push({ 
                    devid: item.SerialNo,
                    devname: item.SiteName,
                    site_status: 'down',
                    count: 0,
                    date: '',
                    interface: '',
                    zone: item.Zone,
                    siteName: item.SiteName,
                    underlay1: item.Underlay1,
                    underlay2: item.Underlay2,
                  })
                }
              }
            })
          })

          let count =0;
          let empty_list =[]
          const uniqueData = Object.values(newlist).reduce((acc, curr) => {
            const devid = curr.devid;
            if (!acc[devid]) {
              acc[devid] = curr;
              empty_list.push(curr)
              count = count + 1;
            }
            localStorage.setItem('dash_data', {...acc});
            return {...acc};
          })
          setSiteDetails(empty_list);
          setsites(data);
          setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const groupedData = siteDetails.reduce((acc, item) => {
    const key = item.zone;
    const devid = item.devid;
    if (!acc[key]) {
      acc[key] = [];
    }
    item.zone = key.split(' ').join('')
    acc[key].push(item);
    return {...acc};
  }, {});
  
  const zone3_data = []
  const zone2_data = []
  const zone1_data = []
  const siteGroupedData = siteDetails.map((acc, item) => {
      const zone_keys = acc.zone
      if (zone_keys === 'Zone1'){
        zone1_data.push({acc})
      }if (zone_keys === 'Zone2'){
        zone2_data.push(acc)
      }if (zone_keys === 'Zone3'){
        zone3_data.push(acc)
      }
    })
    
  const zone1Up =[]
  const zone1Down =[]
  const zone1UpDown =[]
  // const zone1Unkown =[]
  if(zone1_data.length > 0){
    const zone1site_details = zone1_data.map((acc, item) =>{
    
      const site_value = acc.acc
      const status = site_value.site_status 
      if( status === 'up' && site_value.count > 1){ 
        zone1Up.push({site_value}) 
      }
      if(status === 'updown') {
        for(let i = 0; i < loadshedingdata.length; i++) {
          if(loadshedingdata[i].SiteName === site_value.devname ){
              site_value.isLoadsheding = 'yes';
              site_value.sheding_time = loadshedingdata[i].start + ' - ' + loadshedingdata[i].end;
          }else 
          {
            site_value.isLoadsheding = ' ';
          }
        }
        zone1UpDown.push({site_value})
      }
      if(status === 'down' || status === '' || site_value < 2 ) {

        for(let i = 0; i < loadshedingdata.length; i++) {
          if(loadshedingdata[i].SiteName === site_value.devname ){
              site_value.isLoadsheding = 'yes';
              site_value.sheding_time = loadshedingdata[i].start + ' - ' + loadshedingdata[i].end;
          }else 
          {
            site_value.isLoadsheding = ' ';
          }
        }
        zone1Down.push({site_value})
      }
    })
  }

  const zone2Up =[]
  const zone2Down =[]
  const zone2UpDown =[]
  // const zone2Unkown =[]
  if(zone2_data.length > 0) {
    const zone2site_details = zone2_data.map((acc, item) =>{
      const site_value = acc
      const status = acc.site_status 
      if( status === 'up'){ 
        zone2Up.push({site_value}) 
      }
      if(status === 'updown') {
        for(let i = 0; i < loadshedingdata.length; i++) {
          if(loadshedingdata[i].SiteName === site_value.devname ){
              site_value.isLoadsheding = 'yes';
              site_value.sheding_time = loadshedingdata[i].start + ' - ' + loadshedingdata[i].end;
          }else 
          {
            site_value.isLoadsheding = ' ';
          }
        }
	zone2UpDown.push({site_value})
      }
      if(status === 'down' ||  status === '') {
        for(let i = 0; i < loadshedingdata.length; i++) {
          if(loadshedingdata[i].SiteName == site_value.devname ){
             site_value.sheding_time = loadshedingdata[i].start + ' - ' + loadshedingdata[i].end; 
	     site_value.isLoadsheding = 'yes'
          }else 
          {
            site_value.isLoadsheding = ' '
          }
        }
        zone2Down.push({site_value})
      }
    })
  }

  const zone3Up =[]
  const zone3Down =[]
  const zone3UpDown =[]
  // const zone3Unkown =[]
  if(zone3_data.length > 0) {
    const zone3site_details = zone3_data.map((acc, item) =>{
      const site_value = acc
      const status = acc.site_status
      if( status === 'up'){ 
        zone3Up.push({site_value}) 
      }
      if(status === 'updown') {
        for(let i = 0; i < loadshedingdata.length; i++) {
          if(loadshedingdata[i].SiteName === site_value.devname ){
              site_value.isLoadsheding = 'yes';
              site_value.sheding_time = loadshedingdata[i].start + ' - ' + loadshedingdata[i].end;
          }else 
          {
            site_value.isLoadsheding = ' ';
          }
        }
	zone3UpDown.push({site_value})
      }
      if(status === 'down' || status === '') {
        for(let i = 0; i < loadshedingdata.length; i++) {
          if(loadshedingdata[i].SiteName == site_value.devname ){
                 site_value.sheding_time = loadshedingdata[i].start + ' - ' + loadshedingdata[i].end;
		 site_value.isLoadsheding = 'yes'
          }else 
          {
          
            site_value.isLoadsheding = ' '
          }
        }
        zone3Down.push({site_value})
      }
    })
  }

  const formatTimestamp = (timestamp) => {
    return moment.unix(timestamp).format('YYYY-MM-DD HH:mm:ss');
  };
  
  const data = [
    ["Sites ",  "TOTAL SITES IN ZONE", "DOWN", "UP-DOWN", "UP"],
    [`Zone 1 - ${zone1_data.length} Sites`, zone1_data.length, zone1Down.length, zone1UpDown.length, zone1Up.length],
    [`Zone 2 - ${zone2_data.length} Sites`, zone2_data.length, zone2Down.length, zone2UpDown.length, zone2Up.length ],
    [`Zone 3 - ${zone3_data.length} Sites`, zone3_data.length, zone3Down.length, zone3UpDown.length, zone3Up.length],
  ];
  
  const zoneSortedList = [
    {id:1 ,length: zone1Down.length, zone: 'zone1', data: zone1Down}, 
    {id:2 ,length: zone2Down.length, zone: 'zone2', data: zone2Down}, 
    {id:3 ,length: zone3Down.length, zone: 'zone3', data: zone3Down}
  ]
  
  const warningsiteSortedList = [
    {id:1 ,length: zone1UpDown.length, zone: 'zone1', data: zone1UpDown}, 
    {id:2 ,length: zone2UpDown.length, zone: 'zone2', data: zone2UpDown}, 
    {id:3 ,length: zone3UpDown.length, zone: 'zone3', data: zone3UpDown}
  ]
  
  const handleZoneView = (event) => {
    history.push('/down', event);
  }
  
  const handleWarningZoneView = (event) => {
    history.push('/updown', event)
  }
  
  const handleBarClick = (chartWrapper, down, updown) => {
    const selection = chartWrapper.getChart().getSelection();
    if (selection.length > 0) {
      const selectedItem = selection[0];
      const rowIndex = selectedItem.row;
      
      if(rowIndex === 0 && selectedItem.column === 2){
        history.push('/down', down[0]);
      }if(rowIndex === 0 && selectedItem.column === 3){
        history.push('/updown', updown[0]);
      }
      
      if(rowIndex === 1 && selectedItem.column === 2){
        history.push('/down', down[1]);
      }if(rowIndex === 1 && selectedItem.column === 3){
        history.push('/updown', updown[1]);
      }
      
      if(rowIndex === 2 && selectedItem.column === 2){
        history.push('/down', down[2]);
      }if(rowIndex === 2 && selectedItem.column === 3){
        history.push('/updown', updown[2]);
      }
      setSelectedBar(rowIndex);
    }
  };
  
  return loading ? (
    <div style={spinnerContainerStyle}>
      <CircularProgress />
    </div>
  ) : (
  <>
     <Chart
        chartType="Bar"
        width="100%"
        height="400px"
        data={data}
        options={options}
        chartEvents={[
          {
            eventName: "select",
            callback: ({ chartWrapper } ) => handleBarClick(chartWrapper, zoneSortedList, warningsiteSortedList),
          },
        ]}
      />
    <div style={{display: 'flex'}} >
      {zoneSortedList.map((ele, idx) => {
        return(<div key={ele} style={{padding: '0.5rem'}}>
              <Button variant="contained" color="error" onClick={(event) =>{
              handleZoneView(ele)
              }} >Zone{idx+1}  -  {ele.length}</Button>
        </div>)
      })}
    </div>
    
    <div style={{display: 'flex'}} >
      {warningsiteSortedList.map((ele, idx) => {
        return(<div key={ele} style={{padding: '0.5rem'}}>
              <Button variant="contained" color="warning" onClick={(event) =>{
              handleWarningZoneView(ele)
              }} >Zone{idx+1}  -  {ele.length}</Button>
        </div>)
      })}
    </div>
  </>
  )
}

const spinnerContainerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "200px",
};
