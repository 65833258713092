import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import axios from "axios";


const InvoiceTable = (data, loading) => {
  console.log(localStorage.getItem("user"));
console.log("data", data)
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  
  const handleDownload = (fileUrl) => {
   console.log("download", fileUrl)
    axios({
      url: fileUrl,
      method: 'GET',
      responseType: 'blob', // Set the response type to 'blob'
    }).then((response) => {
      // Create a blob from the response data
      const blob = new Blob([response.data], { type: 'application/pdf' });

      // Use the URL.createObjectURL() to create a temporary URL for the blob
      const fileUrl = URL.createObjectURL(blob);

      // Create an anchor element with the temporary URL
      const a = document.createElement('a');
      a.href = fileUrl;
      a.download = fileUrl; // Set the desired file name
      document.body.appendChild(a);
      a.click();

      // Cleanup the temporary URL and remove the anchor element
      URL.revokeObjectURL(fileUrl);
      a.remove();
    }).catch((error) => {
      console.error('Error downloading the file:', error);
    });
  };

  const apiData = data.data;
  console.log("apiData", apiData)

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="left">File Name</TableCell>
              <TableCell align="left">Action</TableCell>
            </TableRow>
          </TableHead>
          {apiData.length > 0 ? (
            <TableBody>
              {apiData.map((row,index) => (
                <TableRow
                  key={row}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="left">{row.file_name}</TableCell>
                  <TableCell align="left">
                  {/* <button onClick={() => handleDownload(row.file_name)}>Download File {index + 1}</button> */}
                    <a
                      href={`https://atlas-ov.sys.echosp.link/files/A1535/invoice-pdf/${row.file_name}`}
                      download={row.file_name}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button
                        variant="outlined"
                        size="medium"
                        endIcon={<DownloadIcon />}
                      >
                        Download
                      </Button>
                    </a>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : loading ? (
            <div s>
              <Box
                sx={{
                  display: "flex",
                  padding: "1rem",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <CircularProgress />
              </Box>
            </div>
          ) : (
            "No data"
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[50, 100, 200]}
        component="div"
        count={apiData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
export default InvoiceTable;
