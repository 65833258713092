import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { logout } from "../views/auth/authSlice";
import { useDispatch } from "react-redux";

export default function ButtonAppBar() {
  const dispatch = useDispatch();

  const signOut = (e) => {
    e.preventDefault();
    dispatch(logout());
  };
  const invoice = () => {
    window.location.href = "/invoice";
  };

  const dashboard = () => {
    window.location.href = "/dashboard";
  };

  const support = () => {
    window.location.href = "/support";
  };

  const loadshedding = () => {
    window.location.href = "loadshedding"; // '/loadshedding'
  };

  const home = () => {
    window.location.href = "/";
  };

  const sla = () => {
    window.location.href = "/sla";
  };

  // const menuId = "primary-search-account-menu";

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            // color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            {/* <MenuIcon /> */}
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {/* Forti Manager */}
          </Typography>
          <Button
            color="inherit"
            onClick={(e) => {
              home();
            }}
          >
            Home
          </Button>
          <Button
            color="inherit"
            onClick={(e) => {
              invoice();
            }}
          >
            Invoices
          </Button>
          <Button
            color="inherit"
            onClick={(e) => {
              sla();
            }}
          >
            Sla
          </Button>
          <Button
            color="inherit"
            onClick={(e) => {
              dashboard();
            }}
          >
            Dashboard
          </Button>
          <Button
            color="inherit"
            onClick={(e) => {
              loadshedding();
            }}
          >
            Loadshedding
          </Button>
          <Button
            color="inherit"
            onClick={(e) => {
              support();
            }}
          >
            Support
          </Button>
          <Button
            color="inherit"
            onClick={(e) => {
              signOut(e);
            }}
          >
            Sign Out
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
