import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { setToken } from "../views/auth/authSlice";
import { Post } from "../service/getway";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://port443.co.za/">
        Port443
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function VerifyMAF() {
  const [code, setCode] = React.useState("");
  const [loading, setLoading] = React.useState(true);

  const dispatch = useDispatch();

  const tempId = useSelector((state) => state.auth.tempId);

  const history = useHistory();
  const handleSubmit = async (event) => {
    event.preventDefault();
    Post(`/verifymfacode/${tempId}`, "", { code })
      .then((response) => {
        setLoading(false);
        console.log(response, "response");

        const { token } = response.data;

        dispatch(setToken(token.access_token));

        history.push("/dashboard");
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        // enqueueSnackbar("Failed to get response", { variant: "error" });
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h2 className="main-header" style={{ color: "black" }}>
          Atlas One View
        </h2>
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography
          component="h1"
          variant="h5"
          sx={{ color: "secondary.main" }}
        >
          MFA Code Verification
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            name="mfa-code"
            label="MFA code"
            type="number"
            id="number"
            value={code}
            onChange={(e) => {
              setCode(e.target.value);
            }}
          />
          <Button
            type="submit"
            size="large"
            fullWidth
            loading={loading}
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={(e) => {
              handleSubmit();
            }}
          >
            continue
          </Button>
        </Box>
        <Box>
          <Link href="/mfa-link" variant="body2">
            link 2 factor authentication (2fa)
          </Link>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
