import React from "react";
import Update from "./components/update";
import { BrowserRouter as Router } from "react-router-dom";
import Invoice from "./components/invoices";
import Support from "./components/support";
import Dashboard from "./components/dashboard";
import Sla from "./components/sla";
import SiteDetail from "./components/siteDetails";

import Eskom from "./components/eskom";
import VerifyMAF from "./components/mfa";
import ZoneDashboard from "./components/zoneDetail";
import PrivateRoute from "./routes/privateRoute";
import PublicRoute from "./routes/publicRoute";
import SignIn from "./views/auth/login/login";
import LinkMAF from "./components/mfaSetup";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ZoneDown from "./components/zonedownDatails";
import WarningSite from "./components/WarningSite";

import "./App.css";
import Read from "./components/read";

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div>
        <Router>
          <div>
            {/* Public Route */}
            <PublicRoute path="/login" component={SignIn} />
            <PublicRoute path="/mfa" component={VerifyMAF} />
            <PublicRoute path="/mfa-link" component={LinkMAF} />
            {/* Private Routes */}
            <PrivateRoute exact path="/" component={Read} />
            <PrivateRoute path="/private" component={SiteDetail} />
            <PrivateRoute path="/sla" component={Sla} />
            <PrivateRoute path="/update" component={Update} />
            <PrivateRoute path="/dashboard" component={Dashboard} />
            <PrivateRoute path="/invoice" component={Invoice} />
            <PrivateRoute path="/support" component={Support} />
            <PrivateRoute path="/loadshedding" component={Eskom} />
            <PrivateRoute path="/sites/:siteName" component={ZoneDashboard} />
            <PrivateRoute path="/down" component={ZoneDown} />
            <PrivateRoute path="/updown" component={WarningSite} />

          </div>
        </Router>
      </div>
    </LocalizationProvider>
  );
}

export default App;
