import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoggedIn: false,
  token: undefined,
  tempId: undefined,
  filteredData: [],
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.tempId = action.payload;
    },
    logout: (state) => {
      state.isLoggedIn = false;

      state.token = null;

      localStorage.clear();
    },
    setToken: (state, action) => {
      state.token = action.payload;
      state.isLoggedIn = true;
    },
    setFilteredData: (state, action) => {
      state.filteredData = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { login, logout, setToken, setFilteredData } = authSlice.actions;

export default authSlice.reducer;
