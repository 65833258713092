import React from "react";
import { Chart } from "react-google-charts";
import { Pie } from "./Charts/pie";
import { Panel } from "@grafana/ui";
import { Divider } from "@mui/material";
import { GridRow } from "semantic-ui-react";

export default function SiteDetail() {
  const data = [
    ["SiteName", "ProductID", "ContractID", "GPSCoordinates"],
    ["ATLAS-ERMELO-5-200", 3995, 19403, { lat: -26.5123582, lng: 29.9856157 }],
  ];

  const options = {
    title: "Atlas Finance Site Information",
    width: 600,
    height: 400,
    hAxis: {
      title: "Site Information",
    },
    vAxis: {
      title: "Value",
    },
  };

  const siteName = "ATLAS-ERMELO-5-200";
  const customerName = "Atlas Finance (Pty) Ltd";
  const routerIp = "100.64.104.16";
  const serialNo = "FGT40FTK22032094";

  return (
    <div>
      <h2 className="main-header" style={{ color: "black" }}>
        Site Details
      </h2>

      <GridRow>
        <Panel>
          <Panel.Title>{siteName}</Panel.Title>
          <Panel.SubTitle>{customerName}</Panel.SubTitle>
        </Panel>
        <Divider />
        <Panel>
          <Panel.Title>Router Information</Panel.Title>
          <Panel.SubTitle>IP: {routerIp}</Panel.SubTitle>
          <Panel.SubTitle>Serial Number: {serialNo}</Panel.SubTitle>
        </Panel>
      </GridRow>
      <hr />
      <br />
      <Chart
        chartType="ColumnChart"
        data={data}
        options={options}
        width={"100%"}
        height={"400px"}
      />
      <Pie />
    </div>
  );
}
