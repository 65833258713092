import React, { useState, useEffect } from "react";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import { Get } from "../service/getway";
import { useSelector } from "react-redux";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper
} from "@mui/material";

export const EventTable = ({ events, currentTime, loading }) => {
  return (
  <Paper>
    <Table>
      <TableHead>
        <TableRow>

          <TableCell>Site Name</TableCell>
          <TableCell>Start</TableCell>
          <TableCell>End</TableCell>
          <TableCell>Zone</TableCell>
          <TableCell>Note</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {loading ? (
          <CircularProgress />
        ) : (
          events.map((entry) => (
            <TableRow
              key={entry.ID} 
            >
              <TableCell>{entry.SiteName}</TableCell>

              <TableCell>
                {moment(entry.start).format("YYYY-MM-DD HH:mm")}
              </TableCell>
              
              <TableCell>
                {" "}
                {moment(entry.end).format("YYYY-MM-DD HH:mm:ss")}
              </TableCell>
              
              <TableCell>
                {entry.zone}
              </TableCell>
              
              <TableCell sx={eventRowStyle(moment(entry.start), moment(entry.end))}>{entry.note}</TableCell>
            </TableRow>
          ))
        )}
      </TableBody>
    </Table>
    </Paper>
  );
};

const Eskom = () => {
  const [events, setEvents] = useState([]);
  const [currentTime] = useState(moment());
  const [loading, setLoading] = useState(true);
  const {token}=  useSelector((state) => state.auth)


  useEffect(() => {
    fetchEventData();
  }, []);

  const fetchEventData = async () => {
    setLoading(true);
    Get("/eskom", token)
      .then((response) => {
        
        Get("/site", token)
        .then(async(sites_response) => {
        const sites = Object.entries(sites_response.data);
        let siteArray =  sites[1][1]
        const { data } = response.data;
        for (let j = siteArray.length -1; j >= 0 ; j--) {
          for (let i =0; i < data.length; i++) {
            if(data[i].ESPID ===  siteArray[j].ESPID){
              data[i].zone = siteArray[j].Zone
            }
          }
        }
        
        setEvents(data);
        setLoading(false);
      })
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  return (
    <div>
      <h2 className="main-header" style={{ color: "black" }}>
        Loadshedding
      </h2>
      <div style={refreshButtonContainerStyle}></div>
      {loading ? (
        <div style={spinnerContainerStyle}>
          <CircularProgress />
        </div>
      ) : (
        <EventTable events={events} currentTime={currentTime} />
      )}
    </div>
  );
};

const spinnerContainerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "200px",
};

const refreshButtonContainerStyle = {
  textAlign: "center",
  margin: "16px 0",
};

const tableHeaderStyle = {
  backgroundColor: "lightgrey",
  padding: "8px",
  textAlign: "left",
};

const tableCellStyle = {
  padding: "8px",
  borderBottom: "1px solid lightgrey",
};

const eventRowStyle = (start, end) => {
  const isCurrentTimeInRange = moment().isBetween(start, end);
  
  let backgroundColor = "";

  if (isCurrentTimeInRange) {
    backgroundColor = "#ED2B2A";
  } else{
    backgroundColor = "#F7B505";
  }

  return {
    backgroundColor,
    color: "white",
    fontWeight: "bold",
  };
};

export default Eskom;
